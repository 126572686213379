<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid style="max-width: 1600px">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Search
              <span style="padding-left: 2px">({{ totalRecord }})</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    ref="name"
                    v-model="searchParam.name"
                    type="text"
                    label="Name"
                    @keydown.enter="searchAllLocation()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    ref="city"
                    v-model="searchParam.city"
                    type="text"
                    label="City"
                    @keydown.enter="searchAllLocation()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    ref="state"
                    v-model="searchParam.state"
                    type="text"
                    label="State"
                    hint="Short state name (Ex. Texas - TX)"
                    persistent-hint
                    @keydown.enter="searchAllLocation()"
                  />
                </v-col>
                <v-col class="xs" cols="12" md="3" sm="4">
                  <v-btn
                    class="blue white--text dark-3"
                    small
                    @click="searchAllLocation()"
                  >
                    <v-icon small> mdi-search-web </v-icon>
                    Filter
                  </v-btn>
                  <v-btn class="ml-1" small @click="resetSearch()">
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-col>
    <v-col cols="12">
      <v-container id="tblLocations" fluid class="pt-0 data-container">
        <v-data-table
          id="tblData"
          ref="table"
          :headers="headers"
          :items="locationList"
          class="elevation-2 data-table"
          calculate-widths
          hide-default-footer
          :loading="loadingIcon"
          fixed-header
          :items-per-page="pageSize"
        >
          <template v-slot:item.name="{ item }">
            <router-link
              :to="{
                name: 'Location Detail',
                params: { id: item.id },
              }"
              class="name-link"
            >
              {{ item.name }}
            </router-link>
          </template>
          <template v-slot:footer>
            <v-page
              ref="table-footer"
              :total-row="totalRecord"
              :page-size-menu="[10, 20, 50, 100]"
              class="pt-3 pr-3 pb-3"
              @page-change="readDataFromAPI"
            />
          </template>
        </v-data-table>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "AllLocation",
  data() {
    return {
      searchParam: {
        name: null,
        city: null,
        state: null,
      },
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "City", value: "city", align: "start" },
        { text: "State", value: "stateProvinceRegion", align: "start" },
        { text: "Shipments", value: "shipmentCount", align: "center" },
      ],
      locationList: [],
      queryParams: {},
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
    }
  },
  computed: {
    ...mapGetters("location", {
      locations: "locations",
    }),
    ...mapGetters("generic", {
      loadingIcon: "loadingIcon",
    }),
  },
  watch: {
    locations() {
      this.totalRecord = this.locations.count
      this.locationList = this.locations.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.locationList = []
    this.getLocations()
  },
  methods: {
    getLocations() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
    },
    resetSearch() {
      this.searchParam.name = null
      this.searchParam.city = null
      this.searchParam.state = null
      this.locationList = []
      this.queryParams = {}
      this.getLocations()
    },
    createQueryString() {
      this.queryParams = {}
      this.queryParams.limit = 20
      this.queryParams.offset = 0
      if (this.searchParam.name) {
        this.queryParams.name__icontains = this.searchParam.name
      }
      if (this.searchParam.city) {
        this.queryParams.city__icontains = this.searchParam.city
      }
      if (this.searchParam.state) {
        this.queryParams.state_province_region__iexact = this.searchParam.state
      }
    },
    searchAllLocation() {
      this.locationList = []
      this.createQueryString()
      this.$store.dispatch("location/GET_LOCATIONS", this.queryParams)
    },
    readDataFromAPI(value) {
      this.page = value.pageNumber
      this.pageSize = value.pageSize
      let pageNumber = this.pageSize * this.page - this.pageSize
      if (pageNumber < 0) {
        pageNumber = 0
      }
      this.queryParams.offset = pageNumber
      this.queryParams.limit = this.pageSize
      this.$store.dispatch("location/GET_LOCATIONS", this.queryParams)
    },
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.name-link:hover {
  text-decoration: none;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
